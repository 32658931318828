import React from "react"
import Obfuscate from "react-obfuscate";
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import Layout from "../components/layout"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {

  const Weeknotes = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .filter(edge => edge.node.frontmatter.path.indexOf('/weeknotes/') > -1)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

    const Articles = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .filter(edge => edge.node.frontmatter.path.indexOf('/articles/') > -1)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <div className="container py-4 py-md-5">
        <SEO title="Home" />
        <div className="row">
          <div className="col col-lg-8">
            <div className="pb-md-5">
              <div className="lead">
                <h1 className="d-none">Samuel Cotterall</h1>
                <p>Hi, I’m Samuel. I’m a <a href="https://www.linkedin.com/in/samuelcotterall/">senior software engineer</a> based in the UK.</p>
                <p>These are my personal weeknotes.</p>
                <p>{" "}<Obfuscate email="hi@samuelcotterall.com" /></p>
              </div>
            </div>
            <h2>Articles</h2>
            <ul className="list list-unstyled page-list pb-4 pb-md-5">
              {Articles}
            </ul>
            <h2>Weeknotes <small className="text-muted">(most Sundays)</small></h2>
            <ul className="list list-unstyled page-list pb-4 pb-md-5">
              {Weeknotes}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            tags
          }
        }
      }
    }
  }
`


export default IndexPage
